<template>
	<div class="vg_wrapper">
		<el-card>
			<div class="vd_search_group">
				<el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getTableData()" label-width="120px">
					<el-row>
						<el-col :md="8">
							<el-form-item label="成品采购号:">
								<el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写成品采购号"/>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="客户货号:">
								<el-input size="small" v-model.trim="searchForm.cust_artno" clearable placeholder="请填写客户货号"/>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="工厂经办人:">
								<el-input size="small" v-model.trim="searchForm.fact_stff_name" clearable placeholder="请填写工厂经办人"/>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :md="8">
							<el-form-item label="单据状态:">
								<el-select
										v-model="searchForm.fact_status"
										filterable
										placeholder="请选择单据状态"
										clearable
										size="small">
									<el-option
											v-for="item in statusList"
											:key="item.value"
											:label="item.label"
											:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="*所属账套:" v-if="userInfo.acct_id === 1">
								<el-select
										v-model="searchForm.acct_id"
										filterable
										placeholder="请选择所属账套"
										clearable
										size="small">
									<el-option
											v-for="item in acctList"
											:key="item.acct_id"
											:label="item.acct_no"
											:value="item.acct_id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
          <el-row>
            <el-col :md="16">
              <el-form-item label="采购日期:">
                <el-date-picker
                    size="small"
                    v-model="timeArray"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" class="vg_ml_16" @click="getDataList()">查询</el-button>
                <el-button size="small" type="info" icon="el-icon-refresh-right" class="vd_export" @click="buttonRefresh()">刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="vd_button_group vg_mtb_16">
            <el-button v-if="userInfo.acct_id !== 1" size="small" type="success" icon="el-icon-document-add" @click="addRow">新增</el-button>
            <el-button v-if="userInfo.acct_id !== 1" size="small" type="danger" icon="el-icon-delete" @click="deleteById">删除</el-button>
          </div>
				</el-form>
			</div>
			<el-row>
				<el-col :md="24">
					<el-table ref="multiTable" class="vg_cursor" v-loading="loadingFlag" :data="tableData" border @row-dblclick="dbClickJp"  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column type="index" label="序号" width="50" align="center"/>
						<el-table-column label="成品采购号" prop="modr_no" min-width="60"/>
						<el-table-column label="客户货号" prop="cust_artno" min-width="60"/>
						<el-table-column label="物料款数" prop="fact_mtrb_total" min-width="40" align="center"/>
						<el-table-column label="采购日期" prop="fact_date" min-width="60">
							<template v-slot="scope">
								<span>{{scope.row.fact_date | formatDate }}</span>
							</template>
						</el-table-column>
						<el-table-column label="工厂经办人" prop="stff_name" min-width="50"/>
            <el-table-column label="状态" prop="status" width="120" align="center" :formatter="formatStatus"/>
						<el-table-column label="*所属账套" prop="acct_no" align="center" width="120" v-if="userInfo.acct_id === 1"/>
						<el-table-column label="操作" prop="driver_contact" align="center" width="150">
							<template v-slot="scope">
								<el-button v-if="userInfo.acct_id !== 1" type="warning" size="mini" plain @click="showDialog(scope.row)">入库</el-button>
								<span  v-if="userInfo.acct_id === 1" class="vg_9f9a9a">暂无</span>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :md="24">
					<pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination"/>
				</el-col>
			</el-row>
			<TableViewPrint @closeDialog="closeDialog"
			                :dialogVisible="dialogVisible"
			                :dialogForm="dialogForm"
			                @changeData="changeData"
			                @deleteRow="deleteRow"
											:stckBinList="binList"></TableViewPrint>
		</el-card>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"
import {acctAPI} from "@api/modules/acct";
import {factAPI} from "@api/modules/fact";
import TableViewPrint from "@/views/PurcManagement/FactManage/TabChild/Compont/Dialog/TableViewPrint";
import {optnAPI} from "@api/modules/optn";

export default {
	name: "factList",
	components: {
		pubPagination,
		TableViewPrint
	},
	data() {
		return {
			dialogVisible: false,
			searchForm: {
				page_no: 1
			},
			dialogForm: {
				cust_artno: null,
				stck_type: null,
				fact_mtrb_list: null,
			},
			totalPage: 0,
			tableData: [],
			btn: {},
			timeArray:[],
      acctList: [],// 所属账套
      acct_id: 2,
			loadingFlag: true,
			statusList: [
				{
					value: '0',
					label: '未下发'
				}, {
					value: '1',
					label: '部分下发'
				}, {
					value: '2',
					label: '已完结'
				}
			],
			multiSelection: [],
			binList: [],
			userInfo:{}
		}
	},
	created() {
		this.userInfo = this.$cookies.get('userInfo')
		this.initData()
	},
	watch: {
	},
	filters: {
		// 时间转换
		formatDate(row) {
			return helper.toTimeDay(row)
		},
	},
	methods: {
		addRow() {
			this.jump("/fact_add");
		},
		initData() {
			this.loadingFlag = true
			this.getAcct();
			this.getDataList();
			this.getBinList();
		},
		// 获取产品信息
		getDataList() {
			const {startTime, endTime} = this.helper.getTime(this.timeArray);
			this.searchForm.start_time = startTime
			this.searchForm.end_time = endTime
			get(factAPI.get_facts, this.searchForm).then(({data}) => {
				if (data.code === 0) {
					this.tableData = data.data.list;
					this.totalPage = data.data.total
					this.btn = data.data.btn;
					setTimeout(() => {
						this.loadingFlag = false
					}, 500);
				}
			});
		},
		deleteById() {
			let ids = this.multiSelection.map(({fact_id}) => (Object.values({fact_id}).toString()))
			post(factAPI.deleteFacts, {fact_id_list: ids}).then(({data}) => {
				if (data.code === 0) {
					this.getTableData();
				}
			});
		},
		// 刷新
		buttonRefresh() {
			this.searchForm = {
				page_no:1,
				modr_no:null,
				cust_artno:null,
				fact_stff_name:null,
				fact_status:null,
				acct_id:null,
			}
			this.loadingFlag = true
			this.currentPage = 1
			this.$refs.pubPagination.currentPage = 1
			this.timeArray = [];
			this.initData()
		},
		// 查询方法
		getTableData() {
			this.loadingFlag = true
			this.$refs.pubPagination.currentPage = 1
			this.initData()
		},
		// 分页查询
		changePageSearch(val) {
			this.searchForm.page_no = val
			this.initData()
		},
		// 双击row跳转到详情编辑
		dbClickJp(row) {
			const permId = this.$route.query.perm_id
			this.jump('/fact_edit', {perm_id: permId, form_id: row.fact_id})
		},
    //获取账套
    getAcct() {
	    get(acctAPI.getAllAcctsV1, {}).then((res) => {
		    if (res.data.code === 0) {
			    this.acctList = res.data.data;
		    }
	    });
    },
		closeDialog() {
			this.dialogVisible = false;
			this.getDataList()
		},
		showDialog(row) {
			this.dialogVisible = true;
			this.dialogForm = row;
		},
		// 多选
		handleSelectionChange(val) {
			this.multiSelection = val;
		},
		changeData(data) {
			this.dialogForm = data;
			this.dialogForm.cust_artno = data.fact_mtrb_list[0].cust_artno
		},
		// 获取库位信息
		getBinList(){
			get(optnAPI.getAllContent, {perm_id: 10006}).then(({data}) => {
				if (data.code === 0) {
					this.binList = data.data
				}
			});
		},
		deleteRow(index) {
			this.dialogForm.fact_mtrb_list.splice(index, 1);
		},
		formatStatus(row) {
			return this.statusList.find(({value}) => Number(value) === Number(row.fact_status)).label;
		},
	}
};
</script>

<style scoped lang="scss">
.vd_button_group{
	width: 100vw;
	position: relative;
}
.vd_txta {
	position: absolute;
	left: 35%;
}
::v-deep .vd_tenpd{
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.vd_img{
	margin-top: 5px;
	width: 40px;
}
.vd_notpass{
	color: rgb(187, 187, 187);
}
.vd_pass{
	color: #188dff;
}
.vd_complate{
	color: #36ab60;
}
.vd_disable{
	color:#d81e06;
}
.vd_wei{
	color: #ff8511;
}
.vd_div{
	width: 40px;
	height: 20px;
	border: none;
	margin-right: 5px;
}
.vd_dis{
	display: flex;
}
.vd_elimg {
	margin-top: 5px;
	width: 60px;
	height: 60px;
}
</style>
