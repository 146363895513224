<template>
	<el-dialog title="物料入库" :visible.sync="dialogVisible" :before-close="closeDialog" width="50%" @open="getData">
		<el-row>
			<el-col :md="QRform.QRimgrul?16:24">
				<el-form ref="dialogForm" :model="dialogForm" :rules="rules">
					<!-- <el-form-item label="材料采购号" label-width="140px" prop="modr_no" class="vg_mb_5">
						<el-input v-model="dialogForm.modr_no" placeholder="请输入材料采购号" autocomplete="off" size="small" maxlength="30"/>
					</el-form-item> -->
					<el-form-item label="成品采购号" label-width="140px" prop="modr_no" class="vg_mb_5">
						<el-input v-model="dialogForm.modr_no" autocomplete="off" placeholder="请输入成品采购号" size="small" maxlength="30"/>
					</el-form-item>
					<!-- <el-form-item label="请选择入库类型" label-width="120px" prop="stck_type">
						<el-select v-model="dialogForm.stck_type" filterable placeholder="请选择类型" clearable size="small">
							<el-option v-for="item in stckTypeList" :key="item.value" :label="item.label" :value="item.value"/>
						</el-select>
					</el-form-item> -->
					<el-table ref="multiTable" :data="dialogForm.fact_mtrb_list" border>
						<el-table-column label="材料名称">
							<template v-slot="scope">
								<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
									<el-input v-model="dialogForm.fact_mtrb_list[scope.$index].mtrl_name" autocomplete="off" size="small" :disabled="true"/>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="数量">
							<template v-slot="scope">
								<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_num'" :rules="[{required:true}]">
									<el-input v-model="dialogForm.fact_mtrb_list[scope.$index].mtrl_num" autocomplete="off" size="small" :disabled="true"/>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="库位">
							<template v-slot="scope">
								<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.stck_bin'" :rules="[{required:true}]">
									<el-select v-model="dialogForm.fact_mtrb_list[scope.$index].stck_bin" filterable placeholder="请选择库位" clearable size="small">
										<el-option v-for="item in stckBinList" :key="item.id" :label="item.param1" :value="item.param1"/>
									</el-select>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template v-slot="scope">
								<el-link type="primary" plain size="mini" @click="generateRow(scope.row)">生成二维码</el-link>
								<el-link class="vg_ml_16" type="danger" plain size="mini" @click="deleteRow(scope.$index)">删除</el-link>
							</template>
						</el-table-column>
					</el-table>
				</el-form>
			</el-col>
			<el-col :md="8" v-if="QRform.QRimgrul">
				<el-row class="vg_pl_16c vg_mb_16">
					<el-col>
						<el-image
						class="showImg"
						:src="QRform.QRimgrul"
						fit="contain"></el-image>
					</el-col>
					<el-col>
						成品采购号:{{dialogForm.cust_artno}}
					</el-col>
					<el-col>
						物料名称:{{QRform.mtrl_name}}
					</el-col>
					<el-col>
						数量:{{QRform.mtrl_num}}
					</el-col>
					<el-col>
						<el-link type="primary" @click="download">下载图片</el-link>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import {get, post} from "@api/request";
import {factAPI} from "@api/modules/fact";
import {mainAPI} from "@api/modules/main";

export default {
	name: "TableViewPrint",
	props: {
		dialogVisible: {
		},
		dialogForm: {
		},
		stckBinList: {},
	},
	data() {
		return {
			rules: {
				cust_artno: [{required: true, trigger: 'change', message: ' '},],
				stck_type: [{required: true, trigger: 'change', message: ' '},],
			},
			tableData: [],
			stckTypeList: [
				{label: "工厂库存", value: 0},
				{label: "订单入库", value: 1},
				{label: "生产制作", value: 2},
				{label: "其他", value: 3},
				{label: "翻单", value: 4},
			],
			QRform:{
				QRimgrul:'',
				QRimgrulCopy:'',
				podr_no:null,
				mtrl_name:null,
				mtrl_num:null
			},
			userInfo:{},
		}
	},
	created() {
		this.userInfo = this.$cookies.get('userInfo')
	},
	methods: {
		closeDialog() {
			this.$emit("closeDialog")
		},
		confirm() {
			this.$refs['dialogForm'].validate((valid) => {
				if (valid) {
					let userInfo = this.$cookies.get("userInfo");
					this.dialogForm.fact_mtrb_list.forEach(item => {
						item.stck_num = item.mtrl_num;
						item.stfw_type = this.dialogForm.stck_type;
						item.acct_id = userInfo.acct_id;
						item.user_id = userInfo.user_id;
						item.stff_id = userInfo.stff_id;
						item.dept_id = userInfo.dept_id;
						item.fact_flag = 1;
						item.cptt_type = userInfo.cptt_type; 
						item.dept_team_id = userInfo.dept_team_id;
						item.podr_no = this.dialogForm.modr_no;
					});
					post(mainAPI.stckInByProcFact, this.dialogForm.fact_mtrb_list).then(({data}) => {
						if (data.code === 0) {
							this.$message.success("入库成功!");
							this.closeDialog();
						}
					});
				} else {
					console.log('error submit!');
					return false;
				}
			});
		},
		getData() {
			get(factAPI.get_fact_by_id, {fact_id: this.dialogForm.fact_id}).then(({data}) => {
				if (data.code === 0) {
					// 权限按钮
					this.btn = data.data.btn;
					this.changeData(data.data.form)
					setTimeout(() => {
						this.loadingFlag = false
					}, 500);
				}
			});
		},
		// 生成二维码
		generateRow(val){
			this.QRform.mtrl_name = val.mtrl_name;
			this.QRform.mtrl_num = val.mtrl_num;
			post(factAPI.createQrcode, val)
			.then(res => {
				let mg = res.data.msg;
				if (res.data.code === 0) {
					let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
					this.QRform.QRimgrul = this.helper.picUrl(res.data.data.qrcd_url, 'l', updateTime, 0)
					this.QRform.QRimgrulCopy = res.data.data.qrcd_url;
				}else {
					let tp = 'error';
					this.$message({message: mg, type: tp});
				}
			})
			.catch(res => {
				let mg = res.data.msg;
				let tp = 'error';
				this.$message({message: mg, type: tp});
				this.initData()
			});
		},
		//下载图片
    download(){
			if(this.QRform.QRimgrul){
				// let docuUrl = this.helper.megPath(this.QRform.QRimgrulCopy,0)
				// http://localhost:8885/file/document/20211216/1639639858419.jpg
				let name = '二维码.jpg'
				this.helper.downloadItem(this.QRform.QRimgrul,name,0)
			}
    },
		changeData(data) {
			this.$emit('changeData',data)
		},
		deleteRow(index) {
			this.$emit('deleteRow',index)
		},
	},
}
</script>

<style scoped >

</style>